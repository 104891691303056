import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { ComponentProps } from "react"
import { View } from "react-native"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { ContactIcon } from "src/components/parts/icons/bottomTab/ContactIcon"
import { HomeIcon } from "src/components/parts/icons/bottomTab/HomeIcon"
import { OrganizationIcon } from "src/components/parts/icons/bottomTab/OrganizationIcon"
import { SettingsIcon } from "src/components/parts/icons/bottomTab/SettingsIcon"
import { Colors } from "src/constants/Colors"
import { RootTabs } from "src/constants/RootTabs"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { useBottomTabBadgeData } from "src/recoil/hooks/bottomTab/useBottomTabBadgeData"
import { HomeTab } from "src/screens/bottomTabs/HomeTab"
import { RootTabParamList } from "src/types.d"
import { ContactNetworkDrawer } from "../ContactNetworkDrawer"
import OrganizationManagerNavigator from "./OrganizationManagerNavigator"
import { styles } from "./styles"
import { SettingsTab } from "src/screens/bottomTabs/SettingsTab"
import { useRecoilValue } from "recoil"
import { completedSignUpFlagState } from "src/recoil/atoms/account/completedSignUpFlagState"
import { TeamNoticeType } from "src/aws/customAPI"
import { InterstitialAd } from "src/tags/ads/InterstitialAd"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { routeNameState } from "src/recoil/atoms/routeNameState"

const screenOptions: ComponentProps<typeof BottomTab.Navigator>["screenOptions"] = {
  ...commonHeaderOptions,
  tabBarActiveTintColor: Colors.orange,
  tabBarInactiveTintColor: Colors.warmGrey2,
  tabBarStyle: {
    backgroundColor: Colors.white3,
  },
}

const BottomTab = createBottomTabNavigator<RootTabParamList>()

interface OwnProps {
  sendAnalyticsEvent: (screen: string) => void
}

export const BottomTabNavigator = ({ sendAnalyticsEvent }: OwnProps) => {
  const {
    hasOrganizationNotice,
    unreadContactCount,
    hasPreTeamMember,
    notifications,
    hasServiceInformation,
    hasUnreadThreadMessage,
    hasOrganizationMatchingNotice,
    isShowInterstitialAd,
  } = useBottomTabBadgeData()

  const paidFunctionNotice =
    notifications
      ?.filter(
        (notice) =>
          notice.type === TeamNoticeType.paymentDeadline ||
          notice.type === TeamNoticeType.transferApplicant ||
          notice.type === TeamNoticeType.startExtendOrderTime ||
          notice.type === TeamNoticeType.middleExtendOrderTime
      )
      .some((hasBadge) => !hasBadge.isRead) ?? false
  const showBadge = hasPreTeamMember || hasOrganizationMatchingNotice || paidFunctionNotice

  const completedSignUpState = useRecoilValue(completedSignUpFlagState)

  const routeName = useRecoilValue(routeNameState)

  return (
    <BottomTab.Navigator
      initialRouteName={completedSignUpState ? RootTabs.OrganizationManagerTab : RootTabs.HomeTab}
      screenOptions={screenOptions}
    >
      <BottomTab.Screen
        name={RootTabs.HomeTab}
        component={HomeTab}
        options={{
          title: "ホーム",
          tabBarIcon: ({ focused, color }) => (
            <View style={styles.iconContainer}>
              <HomeIcon filled={focused} color={color} />
              {hasOrganizationNotice || hasUnreadThreadMessage?.hasUnread ? (
                <BadgeIcon size={"small"} position={"rightTop"} />
              ) : null}
            </View>
          ),
        }}
        listeners={{
          tabPress: () => sendAnalyticsEvent(RootTabs.HomeTab),
        }}
      />
      <BottomTab.Screen
        name={RootTabs.ContactNetworkDrawer}
        component={ContactNetworkDrawer}
        options={{
          title: "連絡網",
          headerShown: false,
          tabBarIcon: ({ focused, color }) => (
            <View style={styles.iconContainer}>
              <ContactIcon filled={focused} color={color} backgroundColor={Colors.white3} />
              {unreadContactCount ? <BadgeIcon size={"small"} position={"rightTop"} number={unreadContactCount} /> : null}
            </View>
          ),
        }}
        listeners={{
          tabPress: () => {
            if (!(routeName in ContactNetworkDrawers) && isShowInterstitialAd) {
              InterstitialAd()
            }
            return sendAnalyticsEvent(RootTabs.ContactNetworkDrawer)
          },
        }}
      />
      <BottomTab.Screen
        name={RootTabs.OrganizationManagerTab}
        component={OrganizationManagerNavigator}
        options={{
          title: "団体管理",
          headerShown: false,
          tabBarIcon: ({ focused, color }) => (
            <View style={styles.iconContainer}>
              <OrganizationIcon filled={focused} color={color} />
              {showBadge ? <BadgeIcon size={"small"} position={"rightTop"} /> : null}
            </View>
          ),
        }}
        listeners={{
          tabPress: () => sendAnalyticsEvent(RootTabs.OrganizationManagerTab),
        }}
      />
      <BottomTab.Screen
        name={RootTabs.SettingsTab}
        component={SettingsTab}
        options={{
          title: "設定",
          headerShown: true,
          headerTitleAlign: "center",
          tabBarIcon: ({ focused, color }) => (
            <View style={styles.iconContainer}>
              <SettingsIcon color={color} filled={focused} />
              {hasServiceInformation ? <BadgeIcon size={"small"} position={"rightTop"} /> : null}
            </View>
          ),
        }}
        listeners={{
          tabPress: () => sendAnalyticsEvent(RootTabs.SettingsTab),
        }}
      />
    </BottomTab.Navigator>
  )
}
