import { adWrap, BannerAdSizes } from "src/tags/ads/index"

// バナーのサイズ
// App: BANNER（320×50）、FULL_BANNER（468×60）、LARGE_BANNER（320×100）、MEDIUM_RECTANGLE（300×250）
// Web: number[]（[幅px、高さpx]）
const Banner = (position: Position, size: BannerAdSizes[], idNumber?: number) =>
  adWrap.BannerAd(
    {
      ios: `/13341826/a_rakuraku.ios_Plus_${position}`,
      android: `/13341826/a_rakuraku.and_Plus_${position}`,
      web: `div-gpt-ad-${webAdNo["home_top"]}-0`,
      id: `${position}_${idNumber ?? 0}`,
    },
    size,
    false
  )

export type Position =
  | "home_top"
  | "home_bottom"
  | "join_complete"
  | "group_exclusion"
  | "inbox_upper"
  | "inbox_bottom"
  | "inbox_top"
  | "outbox_upper"
  | "outbox_bottom"
  | "draft_upper"
  | "draft_bottom"
  | "template_upper"
  | "template_bottom"
  | "trash_upper"
  | "trash_bottom"
  | "mail_detail"
  | "send_complete"
  | "menu"

const webAdNo: { [position in Position]: number } = {
  home_top: 1668161771587,
  home_bottom: 1668161837315,
  join_complete: 1668161803960,
  group_exclusion: 1668161892414,
  inbox_upper: 1668161955046,
  inbox_bottom: 1668162032262,
  inbox_top: 1668161771587,
  outbox_upper: 1668162073482,
  outbox_bottom: 1668162131372,
  draft_upper: 1668162174318,
  draft_bottom: 1668162214526,
  template_upper: 1668162254738,
  template_bottom: 1668162291439,
  trash_upper: 1668162329905,
  trash_bottom: 1668162366221,
  mail_detail: 1668162407379,
  send_complete: 1668162455577,
  menu: 1668161771587,
}

export const BannerAd = {
  HomeTop: Banner("home_top", ["BANNER"]),
  HomeBottom_1: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 1),
  HomeBottom_2: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 2),
  HomeBottom_3: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 3),
  HomeBottom_4: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 4),
  HomeBottom_5: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 5),
  HomeBottom_6: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 6),
  HomeBottom_7: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 7),
  HomeBottom_8: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 8),
  HomeBottom_9: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 9),
  HomeBottom_10: Banner("home_bottom", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 10),
  JoinComplete: Banner("join_complete", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"]),
  GroupExclusion: Banner("group_exclusion", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"]),
  InboxUpper_1: Banner("inbox_upper", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 1),
  InboxUpper_2: Banner("inbox_upper", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 2),
  InboxUpper_3: Banner("inbox_upper", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 3),
  InboxUpper_4: Banner("inbox_upper", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 4),
  InboxTop: Banner("inbox_top", ["BANNER"]),
  OutboxUpper_1: Banner("outbox_upper", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 1),
  OutboxUpper_2: Banner("outbox_upper", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 2),
  MailDetail_1: Banner("mail_detail", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 1),
  MailDetail_2: Banner("mail_detail", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"], 2),
  SendComplete: Banner("send_complete", ["MEDIUM_RECTANGLE", "MEDIUM_RECTANGLE"]),
  MenuBottom: Banner("menu", ["ANCHORED_ADAPTIVE_BANNER"]),
}
