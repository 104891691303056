import { useEffect, useMemo } from "react"
import { RootNavigator } from "src/navigation/RootNavigator"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { useAsyncSelector } from "./useAsyncSelector"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { View } from "react-native"
import { useSetRecoilState } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { RegisterAccount } from "src/components/projects/RegisterAccount"
import { commonOptions } from "src/constants/options/commonHeaderOptions"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { isGqlError } from "src/aws/customAPI"
import { CustomAlert } from "src/utils/CustomAlert"
import { confirmTextRetry, errorMessageNetwork } from "src/utils/const"
import { useRefresher } from "src/hooks/useRefresher"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"

export const useRootScreen = () => {
  const { value, isLoading, error } = useAsyncSelector(accountSelector)
  const setTokens = useSetRecoilState(tokensState)
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)
  const refreshAccount = useRefresher(accountRequestIdState)

  const RootScreen = useMemo(() => {
    if (isLoading && value == null) {
      return () => (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <LoadingIndicator />
        </View>
      )
    }

    switch (value?.signupStatus) {
      case "inProgress":
        return RegisterAccount
      case "completed":
      default:
        return RootNavigator
    }
  }, [isLoading, value])

  useEffect(() => {
    if (isGqlError(errorMessageNetwork, error)) {
      CustomAlert.confirm("エラー", errorMessageNetwork, confirmTextRetry).then((confirm) => {
        if (confirm) {
          refreshAccount()
        }
      })
    } else if (!isLoading && value == null) {
      setTokens(undefined)
      setSelectedOrganization(undefined)
    }
  }, [isLoading, value, error, setTokens, setSelectedOrganization, refreshAccount])

  const rootScreenOptions = useMemo(() => {
    if (!value || value.signupStatus === "completed") {
      return {
        headerShown: false,
      }
    } else {
      return {
        ...commonOptions,
        title: "アカウント情報登録",
        headerShown: true,
        headerLeft: () => null,
      }
    }
  }, [value])

  return {
    RootScreen,
    rootScreenOptions,
  }
}
