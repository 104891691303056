import { migrateAccountDataState } from "./../../../recoil/atoms/account/migrateAccountDataState"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { isEqual } from "lodash"
import { InitAccountRequestParams, initOrUpdateAccount } from "src/apis/account/initOrUpdateAccount"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { birthDateState } from "src/recoil/atoms/account/birthDateState"
import { cityIdState } from "src/recoil/atoms/account/cityIdState"
import { firstNameState } from "src/recoil/atoms/account/firstNameState"
import { genderState } from "src/recoil/atoms/account/genderState"
import { jobIdState } from "src/recoil/atoms/account/jobIdState"
import { kanaFirstNameState } from "src/recoil/atoms/account/kanaFirstNameState"
import { kanaLastNameState } from "src/recoil/atoms/account/kanaLastNameState"
import { lastNameState } from "src/recoil/atoms/account/lastNameState"
import { prefectureIdState } from "src/recoil/atoms/account/prefectureIdState"
import { schoolDepartmentIdState } from "src/recoil/atoms/account/schoolDepartmentIdState"
import { schoolIdState } from "src/recoil/atoms/account/schoolIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { citiesSelectorFamily } from "src/recoil/selectors/account/citiesSelectorFamily"
import { jobsSelector } from "src/recoil/selectors/account/jobsSelector"
import { prefecturesSelector } from "src/recoil/selectors/account/prefecturesSelector"
import { schoolDepartmentsSelectorFamily } from "src/recoil/selectors/account/schoolDepartmentsSelectorFamily"
import { schoolsSelectorFamily } from "src/recoil/selectors/account/schoolsSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { Result } from "src/utils/Result"
import { graduationYearState } from "src/recoil/atoms/account/graduationYearState"
import { questionAnswersState } from "src/recoil/atoms/account/questionAnswersState"
import { accountQuestionMasterState } from "src/recoil/atoms/account/accountQuestionMasterState"
import { SignupStatus } from "src/aws/API"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { validateKanas, validateBirthday, isEmptyString } from "src/utils/validate"
import { latestTermsState } from "src/recoil/atoms/account/latestTermsState"
import { openBrowserAsync } from "expo-web-browser"
import { oldRa9Ra9TokenStates, oldRa9Ra9Tokens } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { UserInfo } from "src/apis/migration/getAccountInfoOld"
import { MARRY_QUESTION_ID, KID_QUESTION_ID } from "src/utils/const"
import { migrationAccountSelector } from "src/recoil/selectors/migration/migrationAccountSelector"
import { accountEditState } from "src/recoil/atoms/account/accountEditState"

type Params = {
  mode: "init" | "update"
  onSuccess?: () => Promise<void>
}

export const useAccountFormData = ({ onSuccess, mode }: Params) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const setAccountRequestIdState = useSetRecoilState(accountRequestIdState)
  const oldTokenRa9 = useRecoilValue(oldRa9Ra9TokenStates)
  const { value: accountInfo, isLoading: loadingAccount } = useAsyncSelector(migrationAccountSelector)
  const refreshAccount = useCallback(() => {
    setAccountRequestIdState((n) => n + 1)
  }, [setAccountRequestIdState])
  const {
    resourceUrl: imageUrl,
    localResource: localImage,
    refreshResourceUrl: refreshImageUrl,
    pickLocalImage,
    deleteResourceFlg: deleteImageFlg,
    prepareDeleteResource: prepareDeleteImage,
    uploadResource: uploadLocalImage,
  } = useResource({ type: "accountImage", size: "normal" })
  const localImageUri = useMemo(() => localImage?.uri, [localImage])
  const [firstName, setFirstName] = useAsyncState(firstNameState)
  const resetFirstName = useResetRecoilState(firstNameState)
  const [lastName, setLastName] = useAsyncState(lastNameState)
  const resetLastName = useResetRecoilState(lastNameState)
  const [kanaFirstName, setKanaFirstName] = useAsyncState(kanaFirstNameState)
  const resetKanaFirstName = useResetRecoilState(kanaFirstNameState)
  const [kanaLastName, setKanaLastName] = useAsyncState(kanaLastNameState)
  const resetKanaLastName = useResetRecoilState(kanaLastNameState)
  const { value: account } = useAsyncSelector(accountSelector)
  const email = useMemo(() => account?.email, [account])
  const [birthDate, setBirthDate] = useAsyncState(birthDateState)
  const resetBirthDate = useResetRecoilState(birthDateState)
  const [isRegistering, setIsRegistering] = useState(false)
  const [gender, setGender] = useAsyncState(genderState)
  const resetGender = useResetRecoilState(genderState)
  const { value: jobs } = useAsyncSelector(jobsSelector)
  const jobOptions = useMemo(() => jobs?.map((job) => ({ value: job.id, label: job.label })), [jobs])
  const [jobId, setJobId] = useAsyncState(jobIdState)
  const resetJobId = useResetRecoilState(jobIdState)
  const [userSelectedSchoolId, setSchoolId] = useAsyncState(schoolIdState)
  const refreshSchoolId = useResetRecoilState(schoolIdState)
  const { value: schools } = useAsyncSelector(schoolsSelectorFamily(jobId))
  const schoolOptions = useMemo(() => schools?.map((school) => ({ value: school.id, label: school.label })), [schools])
  const schoolId = useMemo(() => schools?.find(({ id }) => id === userSelectedSchoolId)?.id, [schools, userSelectedSchoolId])
  const [schoolDepartment, setSchoolDepartmentId] = useAsyncState(schoolDepartmentIdState)
  const refreshSchoolDepartment = useResetRecoilState(schoolDepartmentIdState)
  const { value: schoolDepartments } = useAsyncSelector(schoolDepartmentsSelectorFamily(schoolId))
  const schoolDepartmentOptions = useMemo(
    () => schoolDepartments?.map((sd) => ({ value: sd.id, label: sd.label })),
    [schoolDepartments]
  )
  const schoolDepartmentId = useMemo(
    () => schoolDepartments?.find((sd) => sd.id === schoolDepartment)?.id,
    [schoolDepartments, schoolDepartment]
  )
  const [graduationYear, setGraduationYear] = useAsyncState(graduationYearState)
  const refreshGraduationYear = useResetRecoilState(graduationYearState)
  const thisYear = new Date().getFullYear()
  const graduationYearOptions: { value: number; label: string }[] = [...Array(10).keys()]
    .map((i) => thisYear + i)
    .map((y) => ({ value: y, label: `${y}年` }))
  const { value: prefectures } = useAsyncSelector(prefecturesSelector)
  const prefectureOptions = useMemo(
    () => prefectures?.map((prefecture) => ({ value: prefecture.id, label: prefecture.label })),
    [prefectures]
  )
  const [prefectureId, setPrefectureId] = useAsyncState(prefectureIdState)
  const refreshPrefectureId = useResetRecoilState(prefectureIdState)
  const { value: cities } = useAsyncSelector(citiesSelectorFamily(prefectureId))
  const cityOptions = useMemo(() => cities?.map((city) => ({ value: city.id, label: city.label })), [cities])
  const [city, setCityId] = useAsyncState(cityIdState)
  const cityId = useMemo(() => cityOptions?.find((c) => c.value === city)?.value, [cityOptions, city])
  const [questionAnswers, setQuestionAnswers] = useAsyncState(questionAnswersState)
  const refreshQuestionAnswers = useResetRecoilState(questionAnswersState)
  const { value: accountQuestions } = useAsyncSelector(accountQuestionMasterState)
  const { value: latestTerms } = useAsyncSelector(latestTermsState)
  const openTeamsOfService = () => {
    if (latestTerms != null) {
      return openBrowserAsync(latestTerms.service.url)
    }
  }
  const migrateAccountId = oldTokenRa9?.oldRa9Ra9UserId ? parseInt(oldTokenRa9?.oldRa9Ra9UserId) : undefined

  const [migrateAccountData, setMigrateAccountData] = useRecoilState(migrateAccountDataState)

  const firstNameErrorMessage = useMemo(() => {
    if (!migrateAccountData && oldTokenRa9) {
      return firstName == undefined || firstName == "" ? "必須項目です。入力してください。" : ""
    }
    return ""
  }, [firstName, migrateAccountData, oldTokenRa9])

  const kanaLastNameErrorMessage = useMemo(
    () => (validateKanas(kanaLastName || "") ? "" : "カナで入力してください。"),
    [kanaLastName]
  )

  const kanaFirstNameErrorMessage = useMemo(() => {
    if (!migrateAccountData && (kanaFirstName == undefined || kanaFirstName == "") && oldTokenRa9) {
      return "必須項目です。入力してください。"
    }
    return validateKanas(kanaFirstName || "") ? "" : "カナで入力してください。"
  }, [kanaFirstName, migrateAccountData, oldTokenRa9])
  const birthdayErrorMessage = useMemo(
    () =>
      birthDate
        ? validateBirthday(birthDate)
          ? ""
          : "正しい日付を入力してください。なお、入力できる日付は昨年末までとなります"
        : "",
    [birthDate]
  )
  const isMountedRef = useRef(true)
  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const formatBirthDate = (inputDate: string) => {
    return new Date(inputDate)
  }

  const convertGender = (inputGender: number) => {
    switch (inputGender) {
      case 0:
        return "Male"
      case 1:
        return "Female"
      case 2:
        return "NoAnswer"
    }
  }

  const convertJob = (inputJobId: number) => {
    switch (inputJobId) {
      case 122:
        return "1"
      case 123:
        return "2"
      case 124:
        return "3"
      case 125:
        return "4"
      case 115:
        return "5"
      case 116:
        return "6"
      case 117:
        return "7"
      case 109:
        return "8"
      case 90:
        return "9"
      case 101:
        return "10"
      case 93:
        return "11"
      case 106:
        return "12"
      case 107:
        return "13"
      case 118:
        return "14"
      case 119:
        return "15"
      case 80:
        return "16"
      case 81:
        return "17"
      case 108:
        return "18"
      case 110:
        return "19"
      case 127:
        return "999"
      default:
        return "999"
    }
  }

  const [exitJobId, setExitJobId] = useState(false)
  const [exitSchoolId, setExitSchoolId] = useState(false)
  const [exitPrefectureId, setExitPrefectureId] = useState(false)

  useEffect(() => {
    if (!oldTokenRa9 && mode === "init") {
      setFirstName(undefined)
      setLastName(undefined)
      setKanaLastName(undefined)
      setKanaFirstName(undefined)
      setBirthDate(undefined)
      setJobId(undefined)
      setPrefectureId(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, mode])

  useEffect(() => {
    if (oldTokenRa9 && accountInfo && migrateAccountData && !loadingAccount) {
      setKanaFirstName("")
      setFirstName("")
      setLastName(accountInfo.name)
      setKanaLastName(accountInfo.kana)
      setBirthDate(formatBirthDate(accountInfo.birth))
      setGender(convertGender(accountInfo.sex) as any)
      if (accountQuestions) {
        setQuestionAnswers({
          [MARRY_QUESTION_ID]:
            accountQuestions.find((ele) => ele?.id === MARRY_QUESTION_ID)?.choices[accountInfo.marr]?.id ?? "",
          [KID_QUESTION_ID]: accountQuestions.find((ele) => ele?.id === KID_QUESTION_ID)?.choices[accountInfo.kids]?.id ?? "",
        })
        setMigrateAccountData(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, accountInfo, migrateAccountData, accountQuestions, loadingAccount])

  useEffect(() => {
    if (oldTokenRa9 && accountInfo) {
      setJobId(convertJob(accountInfo.occupation))
      if (convertJob(accountInfo.occupation) != "999") {
        setExitJobId(true)
      } else setExitJobId(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, accountInfo])

  useEffect(() => {
    if (oldTokenRa9 && accountInfo && exitJobId) {
      setSchoolId(schoolOptions?.find((school) => school.label === accountInfo.university)?.value)
      setGraduationYear(accountInfo.graduate_date)
      if (schoolOptions?.find((school) => school.label === accountInfo.university)?.value) {
        setExitSchoolId(true)
      } else setExitSchoolId(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, accountInfo, exitJobId, schoolOptions])

  useEffect(() => {
    if (oldTokenRa9 && accountInfo && exitSchoolId) {
      setSchoolDepartmentId(
        schoolDepartmentOptions?.find((schoolDepartment) => schoolDepartment.label === accountInfo?.department)?.value
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, accountInfo, exitSchoolId, schoolDepartmentOptions])

  useEffect(() => {
    if (oldTokenRa9 && accountInfo) {
      const convertPrefecture = (inputPrefecture: string) => {
        const prefecture = prefectureOptions?.find((prefecture) => prefecture.label == inputPrefecture)
        if (prefecture) {
          return prefecture.value
        }
        return undefined
      }
      setPrefectureId(convertPrefecture(accountInfo.prefecture))
      if (convertPrefecture(accountInfo.prefecture)) {
        setExitPrefectureId(true)
      } else setExitPrefectureId(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, accountInfo, prefectureOptions])

  useEffect(() => {
    if (oldTokenRa9 && accountInfo && exitPrefectureId) {
      const convertCity = (inputCity: string) => {
        const city = cityOptions?.find((city) => city.label == inputCity)
        if (city) {
          return city.value
        }
        return undefined
      }
      setCityId(convertCity(accountInfo.city))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTokenRa9, accountInfo, exitPrefectureId, cityOptions])

  const requestParamsResult = useMemo(() => {
    if (
      accessToken == null ||
      account == null ||
      firstName == null ||
      lastName == null ||
      kanaFirstName == null ||
      kanaLastName == null
    ) {
      return Result.Error({ message: "未入力の項目があります" })
    }
    return Result.Ok<InitAccountRequestParams>({
      accessToken,
      signupStatus: account.signupStatus || SignupStatus.inProgress,
      firstName,
      lastName,
      kanaFirstName,
      kanaLastName,
      birthDate,
      gender,
      jobId,
      schoolId,
      schoolDepartmentId,
      graduationYear,
      prefectureId,
      cityId,
      questionAnswers,
      migrateAccountId,
    })
  }, [
    accessToken,
    account,
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    birthDate,
    gender,
    jobId,
    schoolId,
    schoolDepartmentId,
    graduationYear,
    prefectureId,
    cityId,
    questionAnswers,
    migrateAccountId,
  ])
  const update = useCallback(async () => {
    if (isRegistering) {
      return
    }
    if (!requestParamsResult.isOk) {
      await CustomAlert.alert("エラー", requestParamsResult.error.message)
      return
    }
    setIsRegistering(true)
    try {
      const [updateAccountResult, uploadImageResult] = await Promise.all([
        initOrUpdateAccount({ ...requestParamsResult.content, termsVersion: latestTerms?.service.version }),
        uploadLocalImage(),
      ])
      if (!updateAccountResult.isOk) {
        await CustomAlert.alert("エラー", updateAccountResult.error.message)
      } else if (!uploadImageResult.isOk) {
        await CustomAlert.alert("エラー", uploadImageResult.error.message)
      }
      await onSuccess?.()
    } finally {
      if (isMountedRef.current) {
        setIsRegistering(false)
      }
    }
  }, [isRegistering, onSuccess, requestParamsResult, uploadLocalImage, latestTerms])
  const [isDirty, setIsDirty] = useState(false)
  const [initialData] = useAsyncState(accountEditState)
  const refreshAccountEdit = useResetRecoilState(accountEditState)
  const initImageUrl = useRef(imageUrl).current

  useEffect(() => {
    const currentData = {
      firstName,
      lastName,
      kanaFirstName,
      kanaLastName,
      birthDate,
      gender,
      jobId,
      schoolId,
      schoolDepartmentId,
      graduationYear,
      prefectureId,
      cityId,
      questionAnswers,
      imageUrl: deleteImageFlg ? "deleted" : localImageUri ?? initImageUrl,
    }
    setIsDirty(!isEqual({ ...initialData, imageUrl: initImageUrl }, currentData))
  }, [
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    birthDate,
    gender,
    jobId,
    schoolId,
    schoolDepartmentId,
    graduationYear,
    prefectureId,
    cityId,
    questionAnswers,
    initialData,
    initImageUrl,
    localImageUri,
    deleteImageFlg,
  ])

  const isDisabled = useMemo(
    () =>
      isEmptyString(firstName) ||
      isEmptyString(kanaFirstName) ||
      isEmptyString(lastName) ||
      isEmptyString(kanaLastName) ||
      isRegistering ||
      kanaLastNameErrorMessage !== "" ||
      kanaFirstNameErrorMessage !== "" ||
      birthdayErrorMessage !== "",
    [
      firstName,
      lastName,
      kanaFirstName,
      kanaLastName,
      isRegistering,
      kanaLastNameErrorMessage,
      kanaFirstNameErrorMessage,
      birthdayErrorMessage,
    ]
  )
  const refreshFormData = useCallback(() => {
    refreshAccount()
    resetFirstName()
    resetLastName()
    resetKanaFirstName()
    resetKanaLastName()
    resetBirthDate()
    resetGender()
    resetJobId()
    refreshSchoolId()
    refreshSchoolDepartment()
    refreshQuestionAnswers()
    refreshPrefectureId()
    refreshGraduationYear()
    refreshAccountEdit()
    setIsDirty(false)
  }, [
    refreshAccount,
    resetFirstName,
    resetLastName,
    resetKanaFirstName,
    resetKanaLastName,
    resetBirthDate,
    resetGender,
    resetJobId,
    refreshSchoolId,
    refreshSchoolDepartment,
    refreshQuestionAnswers,
    refreshAccountEdit,
    refreshPrefectureId,
    refreshGraduationYear,
    setIsDirty,
  ])

  return {
    imageUrl,
    deleteImageFlg,
    prepareDeleteImage,
    localImageUri,
    refreshImageUrl,
    pickLocalImage,
    uploadLocalImage,
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    birthDate,
    gender,
    setFirstName,
    setLastName,
    setKanaFirstName,
    setKanaLastName,
    setGender,
    updateBirthDate: setBirthDate,
    email,
    isRegistering,
    isDisabled,
    update,
    jobId,
    jobOptions,
    setJobId,
    schoolOptions,
    schoolDepartmentOptions,
    schoolId,
    setSchoolId,
    schoolDepartmentId,
    setSchoolDepartmentId,
    graduationYear,
    graduationYearOptions,
    setGraduationYear,
    prefectureOptions,
    prefectureId,
    setPrefectureId,
    cityId,
    setCityId,
    questionAnswers,
    setQuestionAnswers,
    accountQuestions,
    cityOptions,
    latestTerms,
    openTeamsOfService,
    refreshFormData,
    migrateAccountData,
    firstNameErrorMessage,
    kanaLastNameErrorMessage,
    kanaFirstNameErrorMessage,
    birthdayErrorMessage,
    isDirty,
  }
}
