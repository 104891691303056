import type { CustomAlertIF } from "./CustomAlertIF"
import Swal from "sweetalert2"
import { confirmTextRetry, errorMessageNetwork } from "src/utils/const"

const closeImage =
  "<img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi44MjgiIGhlaWdodD0iMTYuODI4IiB2aWV3Qm94PSIwIDAgMTYuODI4IDE2LjgyOCI+CiAgICA8cGF0aCBkYXRhLW5hbWU9IuKGs0NvbG9yIiBkPSJNMTYuODI4IDEuNjk1IDE1LjEzMyAwIDguNDE0IDYuNzE5IDEuNjk1IDAgMCAxLjY5NWw2LjcxOSA2LjcxOUwwIDE1LjEzM2wxLjY5NSAxLjY5NSA2LjcxOS02LjcxOSA2LjcxOSA2LjcxOSAxLjY5NS0xLjY5NS02LjcxOS02LjcxOXoiIHN0eWxlPSJmaWxsOiNlMjg3MzUiLz4KPC9zdmc+Cg=='/>"

export const CustomAlert: CustomAlertIF = {
  async alert(title, message, error) {
    if (
      message === "利用規約が更新されています。" ||
      message === "メンテナンス情報の登録があります。" ||
      message === "INVALID_ACCESS_TOKEN"
    ) {
      return
    }
    await Swal.fire({
      title: message ? title : "完了",
      text: message ? message : title,
      confirmButtonText: error ? error : "OK",
      confirmButtonColor: "#f0830e",
      showCloseButton: true,
      closeButtonHtml: closeImage,
      customClass: {
        confirmButton: "confirm-button-class",
      },
    })
  },
  async confirm(title, message, error) {
    const confirmButtonText = error === confirmTextRetry ? confirmTextRetry : "OK"
    const result = await Swal.fire({
      title,
      text: message,
      showCancelButton: !error,
      showCloseButton: true,
      closeButtonHtml: closeImage,
      reverseButtons: true,
      cancelButtonText: "キャンセル",
      confirmButtonText,
      confirmButtonColor: "#f0830e",
      customClass: {
        confirmButton: "confirm-button-class",
      },
    })
    return result.isConfirmed
  },
}
