import { useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { validateJapanese, validateKanas } from "src/utils/validate"
import dayjs from "dayjs"
import { listPlanSelector } from "src/recoil/selectors/paidTeam/listPlanSelector"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { PlanData, planDataState } from "src/recoil/atoms/planData/planDataState"
import { defaultValue, getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { orderPlanState } from "src/recoil/selectors/paidTeam/orderPlanState"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { setAsync } from "src/storage/secureStorage/setAsync"
import { getPreOrderDetailState } from "src/recoil/selectors/paidTeam/orderPreDetailState"
import { refreshConfirmScreenState } from "src/recoil/atoms/paidTeam/refreshConfirmScreenState"
import { formatDate } from "src/constants/Day"
import { orderPrePlanState } from "src/recoil/selectors/paidTeam/orderPrePlanState"
import { planMigrateFlag } from "src/recoil/atoms/planData/planMigrateFlag"
import { PlanType } from "src/aws/API"
import { MIGRATE_PLAN_ID } from "src/utils/const"
import { PlanModel } from "src/types/paidTeam/plan"
import { listPlanRequestIdState } from "src/recoil/atoms/paidTeam/listPlanRequestIdState"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"
import { useRefresher } from "src/hooks/useRefresher"
export type initUpdatePlan = {
  planId: string
  startDate?: string
  remark: string | null | undefined
}

type Params = {
  mode: "init" | "update" | "back"
  organizationId: string
  extend?: boolean
  orderId?: string
  oriUpdatePlan?: initUpdatePlan
}

export const usePlanData = ({ organizationId, mode, extend, orderId, oriUpdatePlan }: Params) => {
  const { value: organizationDetail, isLoading: isLoadingOrganizationDetail } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const setRefreshConfirm = useSetRecoilState(refreshConfirmScreenState)
  const { value: plans } = useAsyncSelector(listPlanSelector)
  const compareById = (a: any, b: any) => {
    const idA = parseInt(a.value)
    const idB = parseInt(b.value)
    return idA - idB
  }
  const refreshListPlan = useRefresher(listPlanRequestIdState)
  const refreshOrderDetail = useRefresher(getOrderDetailIdRequestState)
  const [orderDetail, setOrderDetail] = useRecoilState(getOrderDetailState)
  const [oderPreDetail, setOrderPreDetail] = useRecoilState(getPreOrderDetailState)
  const [planDetail, setPlanDetail] = useRecoilState(orderPlanState)
  const [planPreDetail, setPlanPreDetail] = useRecoilState(orderPrePlanState)
  const plan = useRecoilValue(orderPlanState)
  const [initUpdatePlan, setInitUpdatePlan] = useState<initUpdatePlan>()
  const [planData, setPlanData] = useRecoilState(planDataState)
  const errorMessage = "必須項目です。"
  const [runEffect, setRunEffect] = useState(false)
  const migratePaidFlag = useRecoilValue(planMigrateFlag)
  const [planStore, setPlanStore] = useState<PlanData | undefined>()

  const validateRequiredValue = (input: string | undefined) => {
    if (!input && planStore) {
      return errorMessage
    } else if (input === null || input === "") {
      return errorMessage
    } else return ""
  }

  const orderData = extend ? oderPreDetail : orderDetail
  const planDetailData = extend ? planPreDetail : planDetail
  const setData = async () => {
    const planStore = await getAsync("planData")
    setRunEffect(true)
    if (planStore !== undefined) {
      setPlanStore(JSON.parse(planStore))
    } else setPlanStore(undefined)
  }
  const currentOrderPlan = useMemo(
    () => ({
      id: plan?.id ?? "",
      planCode: plan?.planCode ?? "",
      name: plan?.name ?? "",
      type: plan?.type ?? "",
      memberLimit: plan?.memberLimit,
      teamLimit: plan?.teamLimit,
      period: plan?.period ?? 0,
      amount: plan?.amount ?? 0,
      taxRate: 10,
      planType: plan?.planType ?? PlanType.normal,
    }),
    [plan]
  )

  const isMigratedPaidTeam = organizationDetail?.migrationId && organizationDetail.paidFunctionEnabled && migratePaidFlag

  const plansOption = useMemo(() => {
    if (!plans || !organizationDetail) {
      return []
    }
    const filterAndMapPlans = (listPlan: PlanModel[]) => {
      return listPlan
        .filter((plan) => (plan?.memberLimit ?? 0) >= (organizationDetail.memberNumber ?? 0) && plan.planType !== PlanType.end)
        .map((plan) => {
          let label = `${plan.memberLimit}名${plan.name.substring(plan.name.indexOf("("))}`
          if (plan.amount === 0) {
            label = plan.name.substring(plan.name.indexOf("("))
          }
          return {
            value: plan.id,
            label,
          }
        })
        .sort(compareById)
    }
    const filteredPlans = plans.filter((e) => e.id !== currentOrderPlan.id)
    let availablePlans = filterAndMapPlans(mode === "update" && orderId ? [...filteredPlans, currentOrderPlan] : plans)
    const migratePlan = plans.find((p) => p.memberLimit == 0)
    if ((isMigratedPaidTeam || orderData.planId === MIGRATE_PLAN_ID) && migratePlan) {
      availablePlans = [...availablePlans, { value: migratePlan.id, label: "らくらく連絡網からの移行プラン" }]
    }
    return availablePlans
  }, [plans, currentOrderPlan, organizationDetail, isMigratedPaidTeam, orderData.planId, mode, orderId])

  const startTimeOptions = [0, 1, 2].map((startTime) => ({
    value: "" + startTime,
    label: `${dayjs().add(startTime, "month").startOf("month").format(formatDate.PlanStartTime)}`,
  }))

  const planError = useMemo(() => {
    if (planData.planId == undefined || (planData.planId === "" && planStore)) {
      return true
    } else if (
      planData.maxMembers &&
      organizationDetail &&
      organizationDetail.memberNumber &&
      +planData.maxMembers < +organizationDetail.memberNumber
    ) {
      if (planData.planId === MIGRATE_PLAN_ID) {
        return false
      }
      return true
    } else return false
  }, [planStore, organizationDetail, planData])
  const updatedError = useMemo(() => {
    if (!initUpdatePlan) return false
    const { planId, startDate } = initUpdatePlan
    return (
      (extend && planData.planId === planId) ||
      (!extend && planData.planId === planId && planData.startTimeConvert === startDate)
    )
  }, [initUpdatePlan, planData.planId, planData.startTimeConvert, extend])
  const invoiceAddressError = useMemo(() => {
    if (validateRequiredValue(planData.invoiceAddress) !== "") {
      return validateRequiredValue(planData.invoiceAddress)
    }
    return !validateJapanese(planData.invoiceAddress || "") && planData.invoiceAddress != undefined
      ? "請求書の宛名[振込名義]に漢字、カナ、平仮名以外の文字が入力されています。ご確認ください。"
      : ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData.invoiceAddress, planStore])

  const invoiceKanaAddressError = useMemo(() => {
    if (validateRequiredValue(planData.invoiceKanaAddress) !== "") {
      return validateRequiredValue(planData.invoiceKanaAddress)
    }
    return validateKanas(planData.invoiceKanaAddress || "") ? "" : "カナで入力してください。"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData.invoiceKanaAddress, planStore])

  const startTimeError = useMemo(() => {
    if (planData.startTime == undefined && planData.planId !== MIGRATE_PLAN_ID) {
      return true
    } else return false
  }, [planData.startTime, planData.planId])

  const setLocalStorageRealTime = (data: PlanData) => {
    if (mode === "update") return
    setAsync("planData", JSON.stringify(data))
  }

  const isDisabled = useMemo(
    () =>
      updatedError ||
      planError ||
      startTimeError ||
      planData.invoiceAddress == undefined ||
      planData.invoiceKanaAddress == undefined ||
      planData.planId == "" ||
      planData.startTime == "" ||
      (mode === "init" && invoiceKanaAddressError !== "") ||
      invoiceAddressError !== "" ||
      plansOption.find((plan) => plan.value === planData.planId) === undefined,
    [
      mode,
      planError,
      startTimeError,
      planData.invoiceAddress,
      planData.invoiceKanaAddress,
      planData.planId,
      planData.startTime,
      invoiceKanaAddressError,
      invoiceAddressError,
      plansOption,
      updatedError,
    ]
  )

  const changePlan = (e: string | undefined) => {
    const filteredPlans = (plans ?? []).filter((plan) => plan.id !== currentOrderPlan.id)
    const currentPlan = [...filteredPlans, currentOrderPlan]?.find((plan) => plan.id === e && plan.planType !== PlanType.end)

    if (e === MIGRATE_PLAN_ID || e === undefined) {
      setPlanData({
        ...planData,
        maxMembers: ``,
        limitTime: ``,
        price: ``,
        planId: e,
      })
      setLocalStorageRealTime({
        ...planData,
        maxMembers: ``,
        limitTime: ``,
        price: ``,
        planId: e,
      })
    } else {
      const amount = currentPlan?.amount ?? 0
      const price = amount + (amount * (currentPlan?.taxRate ?? 0)) / 100
      setPlanData({
        ...planData,
        planId: e,
        maxMembers: `${currentPlan?.memberLimit}`,
        limitTime: `${currentPlan?.period}`,
        price: `${price.toLocaleString("ja-JP")}`,
      })
      setLocalStorageRealTime({
        ...planData,
        planId: e,
        maxMembers: `${currentPlan?.memberLimit}`,
        limitTime: `${currentPlan?.period}`,
        price: `${price.toLocaleString("ja-JP")}`,
      })
    }
  }

  const gotoConfirmPlan = () => {
    if (isDisabled) return
    setRefreshConfirm((oldValue) => oldValue + 1)
    if (orderDetail.createdAt) {
      setPlanData({
        ...planData,
        startTimeConvert: `${dayjs()
          .add(parseInt(planData.startTime ?? ""), "month")
          .startOf("month")
          .format(formatDate.normal)}`,
      })
      return
    }
    setAsync(
      "planData",
      JSON.stringify({
        ...planData,
        startTimeConvert: `${dayjs()
          .add(parseInt(planData.startTime ?? ""), "month")
          .startOf("month")
          .format(formatDate.normal)}`,
      })
    )
  }
  useEffect(() => {
    setData()
  }, [mode])

  useEffect(() => {
    if (oriUpdatePlan) {
      setInitUpdatePlan(oriUpdatePlan)
    }
  }, [oriUpdatePlan])

  useEffect(() => {
    if (mode === "update" && orderId) {
      if (extend) {
        setInitUpdatePlan({
          planId: oderPreDetail.planId,
          remark: oderPreDetail.applicantRemark,
        })
      } else {
        setInitUpdatePlan({
          planId: orderDetail.planId,
          startDate: orderDetail.startDate,
          remark: orderDetail.applicantRemark,
        })
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])
  useEffect(() => {
    if (mode === "init") {
      setOrderDetail(defaultValue)
      setOrderPreDetail(defaultValue)
    }
  }, [organizationId, mode, setOrderPreDetail, setOrderDetail])
  useEffect(() => {
    refreshListPlan()
    if (mode === "update" && orderId) {
      refreshOrderDetail()
    }
  }, [refreshListPlan, mode, orderId, refreshOrderDetail])
  const convertDateToJapaneseFormat = (date: string): string => {
    const formattedDate = dayjs(date).format(formatDate.PlanStartTime)
    return formattedDate
  }
  useEffect(() => {
    if (runEffect) {
      if (planStore && !orderData.createdAt) {
        setPlanData({
          ...planData,
          startTime: planStore.startTime,
          planId: planStore.planId,
          invoiceAddress: planStore.invoiceAddress,
          invoiceKanaAddress: planStore.invoiceKanaAddress,
          remark: planStore.remark,
          maxMembers: planStore.maxMembers,
          limitTime: planStore.limitTime,
          price: planStore.price,
        })
      } else if (mode === "init" && planStore === undefined) {
        setPlanData({
          ...planData,
          startTime: "0",
          planId: organizationDetail?.migrationId && organizationDetail.paidFunctionEnabled ? "1" : "",
          invoiceAddress: undefined,
          invoiceKanaAddress: undefined,
          remark: "",
          maxMembers: organizationDetail?.migrationId && organizationDetail.paidFunctionEnabled ? "" : undefined,
          limitTime: organizationDetail?.migrationId && organizationDetail.paidFunctionEnabled ? "" : undefined,
          price: organizationDetail?.migrationId && organizationDetail.paidFunctionEnabled ? "" : undefined,
        })
      } else if (mode === "update" && orderData.createdAt) {
        const startDateInJapanese = convertDateToJapaneseFormat(orderData.startDate)
        const matchedOption = startTimeOptions.find((option) => option.label === startDateInJapanese)
        const isEndPlan = plan.planType === PlanType.end
        setPlanData({
          ...planData,
          startTime: matchedOption ? matchedOption.value : undefined,
          invoiceAddress: orderData.invoiceAddressee ?? "",
          invoiceKanaAddress: orderData.invoiceAddresseeKana ?? "",
          planId: isEndPlan ? undefined : orderData.planId ?? "",
          remark: orderData.applicantRemark ?? "",
          price: orderData.amount.toLocaleString("ja-JP") ?? "",
          limitTime: planDetailData.period.toString(),
          maxMembers: planDetailData.memberLimit?.toString(),
          startTimeConvert: orderData.startDate,
        })
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    orderData,
    plan,
    planStore,
    plans,
    runEffect,
    organizationDetail?.migrationId,
    organizationDetail?.paidFunctionEnabled,
  ])
  return {
    organizationDetail,
    isLoadingOrganizationDetail,
    errorMessage,
    isDisabled,
    planError,
    plansOption,
    startTimeOptions,
    startTimeError,
    invoiceAddressError,
    invoiceKanaAddressError,
    gotoConfirmPlan,
    planData,
    setPlanData,
    setLocalStorageRealTime,
    orderData,
    changePlan,
    isMigratedPaidTeam,
    initUpdatePlan,
  }
}
