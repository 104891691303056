import { CustomAlertIF } from "./CustomAlertIF"
import { Alert, Platform } from "react-native"
import { confirmTextRetry, errorMessageNetwork } from "src/utils/const"

export const CustomAlert: CustomAlertIF = {
  async alert(title, message, error) {
    let myTitle = title
    let myBody = message
    if (
      message === "利用規約が更新されています。" ||
      message === "メンテナンス情報の登録があります。" ||
      message === "INVALID_ACCESS_TOKEN" ||
      message === "新しいバージョンのアプリをインストールしてください。"
    ) {
      return
    }
    if (Platform.OS === "android" && !message) {
      myTitle = ""
      myBody = title
    }
    await new Promise<void>((resolve) =>
      Alert.alert(myTitle, myBody, [{ text: error ? error : "OK", onPress: () => resolve() }])
    )
  },
  async confirm(title, message, error) {
    let myTitle = title
    let myBody = message
    const confirmButtonText = error === confirmTextRetry ? confirmTextRetry : "OK"
    if (Platform.OS === "android" && !message) {
      myTitle = ""
      myBody = title
    }
    const result = await new Promise<boolean>((resolve) =>
      Alert.alert(
        myTitle,
        myBody,
        error
          ? [{ text: confirmButtonText, onPress: () => resolve(true) }]
          : [
              { text: "キャンセル", onPress: () => resolve(false) },
              { text: confirmButtonText, onPress: () => resolve(true) },
            ]
      )
    )
    return result
  },
}
