import Constants from "expo-constants"
import { View } from "react-native-web"
// https://rnfirebase.io/analytics/usage
export class AdNative {
  isEnv

  constructor() {
    this.isEnv = Constants.expoConfig?.extra?.env ?? "dev"
  }

  async initialize() {
    return null
  }

  BannerAd(id, sizes, isTest) {
    let adUnitId = this.isEnv == "prd" && !isTest ? id.web : "test"
    let tmp_sizes
    switch (sizes[0]) {
      case "BANNER":
        tmp_sizes = [320, 50]
        break
      case "FULL_BANNER":
        tmp_sizes = [468, 60]
        break
      case "LARGE_BANNER":
        tmp_sizes = [320, 100]
        break
      case "MEDIUM_RECTANGLE":
        tmp_sizes = [300, 250]
        break
      default:
        tmp_sizes = [300, 250]
        break
    }

    return (
      <View>
        <div id={adUnitId} style={{ minWidth: tmp_sizes[0], minHeight: tmp_sizes[1], margin: "5px auto 0" }}>
          <script>
            window.onload = function(){" "}
            {
              // eslint-disable-next-line no-undef
              setTimeout(() => {
                // eslint-disable-next-line no-undef
                googletag.cmd.push(function () {
                  // eslint-disable-next-line no-undef
                  googletag.display(adUnitId)
                })
              }, 5000)
            }
          </script>
        </div>
      </View>
    )
  }

  InterstitialAd(id) {
    return () => {}
  }
}

export const adWrap = new AdNative()
