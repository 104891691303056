import { memo, useMemo } from "react"
import { RootTabs } from "src/constants/RootTabs"
import { Analytics } from "src/tags/analytics/Analytics"

import { BottomTabNavigator } from "./BottomTabNavigator"
import { RootDrawerNavigator } from "./RootDrawerNavigator"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { homeOrganizationsSelector } from "src/recoil/selectors/home/HomeOrganizationsSelector"
import { Platform, View } from "react-native"
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { BannerAd } from "src/tags/ads/BannerAd"
import { useRecoilState } from "recoil"

export const RootNavigator = memo(() => {
  const isPCScreen = useCheckPCScreen()
  const insets = useSafeAreaInsets()
  const bottomTabPaddingBottom = useMemo(() => {
    return Math.max(insets.bottom - Platform.select({ ios: 4, default: 0, android: -10 }), 0)
  }, [insets])

  const [adLoadStateList] = useRecoilState(adLoadState)
  const { value: organizations } = useAsyncSelector(homeOrganizationsSelector)
  const isShowMenuBottomAd = useMemo(() => {
    if (organizations != null) {
      return organizations.every((item) => item.isShowAd)
    }
    return false
  }, [organizations])

  const sendAnalyticsEvent = async (screenName: string) => {
    if (screenName === RootTabs.HomeTab) {
      await Analytics.goToHome()
    } else if (screenName === RootTabs.ContactNetworkDrawer) {
      await Analytics.goToMail()
    } else if (screenName === RootTabs.OrganizationManagerTab) {
      await Analytics.goToTeam()
    } else if (screenName === RootTabs.SettingsTab) {
      await Analytics.goToSetting()
    }
  }

  if (isPCScreen) {
    return <RootDrawerNavigator sendAnalyticsEvent={sendAnalyticsEvent} />
  }
  return (
    <>
      <BottomTabNavigator sendAnalyticsEvent={sendAnalyticsEvent} />
      {Platform.OS !== "web" && isShowMenuBottomAd ? (
        <SafeAreaView edges={["bottom"]}>
          <View
            style={
              !isLoadDoneAd("menu", adLoadStateList)
                ? { height: 0 }
                : {
                    marginTop: -bottomTabPaddingBottom,
                  }
            }
          >
            {BannerAd.MenuBottom}
          </View>
        </SafeAreaView>
      ) : null}
    </>
  )
})

RootNavigator.displayName = "RootNavigator"
