import { memo, useCallback } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, View } from "react-native"
import { ContactItem } from "src/components/parts/ContactItem"
import { ContactListFooter, ContactListFooterMargin } from "src/components/parts/contactNetworkTab/ContactListFooter"
import { ContactQueryBuilder } from "src/components/parts/contactNetworkTab/ContactQueryBuilder"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { OutboxIcon } from "src/components/parts/icons/drawer/OutboxIcon"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { Screens } from "src/constants/Screens"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { DrawerScreenOptions } from "src/navigation/ContactNetworkDrawer"
import { useOutboxData } from "src/recoil/hooks/screens/useOutboxData"
import { ContactNetworkDrawerProps } from "src/types.d"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactListEmpty } from "src/components/parts/contactNetworkTab/ContactListEmpty"
import { ContactFilterType } from "src/types/contact/ContactFilter"
import { DrawerIconContainer } from "src/components/parts/contactNetworkTab/DrawerIconContainer"
import { DrawerLabel } from "src/components/parts/contactNetworkTab/DrawerLabel"
import { useContactNetworkDrawerNavEffect } from "src/recoil/hooks/contact/useContactNetworkDrawerNavEffect"
import { RootTabs } from "src/constants/RootTabs"
import { Analytics } from "src/tags/analytics/Analytics"
import { BannerAd } from "src/tags/ads/BannerAd"
import { Buckets } from "src/aws/customAPI"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
import { MainLayout } from "src/layouts/MainLayout"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"
import { useIsFocused } from "@react-navigation/native"
export type OutboxScreenParams = {
  organizationId?: string
}

export const OutboxScreen = memo<ContactNetworkDrawerProps<typeof ContactNetworkDrawers.OutboxScreen>>(
  ({ navigation, route }) => {
    const { value: accountPermission } = useAsyncSelector(permissionSelector)
    const checkCreateContact = accountPermission?.mail.make
    const {
      searchText,
      setSearchText,
      filters,
      setFilters,
      contacts,
      isLoadingContacts,
      refreshContacts,
      unreadPerContact,
      organizationId,
      refreshContactDetailData,
      execDeleteContact,
      execUpdateFavoriteContact,
      resetCreateNewContactScreenData,
      isReservedContact,
      favoriteFlgsCache,
      setNextTokens,
      organization,
    } = useOutboxData()
    const [adLoadStateList] = useRecoilState(adLoadState)
    const isFocused = useIsFocused()
    const calculateUnreadThreadCount = (contactId: string, unreadThreadCountBuckets: Buckets[]) => {
      const unreadThread = unreadThreadCountBuckets?.find((el) => el.key === contactId)
      return unreadThread?.doc_count ?? 0
    }

    // web版のリロードに対応するため、クエリパラメータを付与してリダイレクト
    useContactNetworkDrawerNavEffect({
      queryParamOrganizationId: route.params?.organizationId,
      screenName: ContactNetworkDrawers.OutboxScreen,
      redirect: (organizationId) => {
        navigation.replace(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: {
            screen: ContactNetworkDrawers.OutboxScreen,
            params: { organizationId: organizationId },
          },
        })
      },
    })

    const gotoCreateNewContact = useCallback(async () => {
      if (organizationId) {
        await Analytics.goToCreateMail(organizationId)
      }
      resetCreateNewContactScreenData()
      navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
    }, [navigation, resetCreateNewContactScreenData, organizationId])
    const gotoContactDetail = useCallback(
      (contactId: string) => {
        const viewMode = isReservedContact(contactId) ? "Reserved" : "Outbox"
        if (!organizationId) {
          return
        }
        refreshContactDetailData()
        Analytics.goToMailDetailFromOutbox(organizationId)
        navigation.navigate(Screens.ContactDetail, {
          contactId,
          organizationId,
          viewMode: viewMode,
        })
      },
      [navigation, organizationId, refreshContactDetailData, isReservedContact]
    )

    const ListItem = useCallback(
      (listRenderItemInfo: ListRenderItemInfo<ContactOverviewModel>) => (
        <View style={styles.contentContainer}>
          <ContactItem
            contactId={listRenderItemInfo.item.id}
            viewType="Outbox"
            reservedDate={listRenderItemInfo.item.reservedDate}
            title={listRenderItemInfo.item.title}
            contactType={listRenderItemInfo.item.contactType}
            sentDate={listRenderItemInfo.item.sentDate}
            timeLimitDate={listRenderItemInfo.item.deadlineDate}
            senderId={listRenderItemInfo.item.senderId}
            senderName={listRenderItemInfo.item.senderName}
            isSenderDeleted={listRenderItemInfo.item.isSenderDeleted}
            isFavorite={listRenderItemInfo.item.isFavorite}
            hasAttachment={listRenderItemInfo.item.isAttachmentExists}
            isEdited={listRenderItemInfo.item.isUpdate}
            updatedDate={listRenderItemInfo.item.updatedDate}
            onIsFavoriteChange={async () => {
              await execUpdateFavoriteContact({
                contactId: listRenderItemInfo.item.id,
                isFavorite: !(favoriteFlgsCache?.[listRenderItemInfo.item.id] ?? listRenderItemInfo.item.isFavorite),
              })
            }}
            favoriteFlgsCache={favoriteFlgsCache}
            onContactPress={() => gotoContactDetail(listRenderItemInfo.item.id)}
            onDeletePress={() => execDeleteContact(listRenderItemInfo.item.id)}
            noBorder
            unreadThreadCount={calculateUnreadThreadCount(listRenderItemInfo.item.id, unreadPerContact ?? [])}
            imageUrl={listRenderItemInfo.item.imgUrl}
          />
          {Platform.OS !== "web" &&
          (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
          listRenderItemInfo.index === 2 ? (
            <View style={!isLoadDoneAd("outbox_upper", adLoadStateList, 1) && { height: 0 }}>{BannerAd.OutboxUpper_1}</View>
          ) : null}
          {Platform.OS !== "web" &&
          (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
          listRenderItemInfo.index === 5 ? (
            <View style={!isLoadDoneAd("outbox_upper", adLoadStateList, 2) && { height: 0 }}>{BannerAd.OutboxUpper_2}</View>
          ) : null}
        </View>
      ),
      [
        unreadPerContact,
        adLoadStateList,
        execUpdateFavoriteContact,
        favoriteFlgsCache,
        gotoContactDetail,
        execDeleteContact,
        organization,
      ]
    )
    const getMoreContacts = useCallback(() => {
      setNextTokens((prev) => (contacts?.nextToken != null ? [...prev, contacts.nextToken] : prev))
    }, [contacts, setNextTokens])

    const renderCreateContactButton = () => {
      if (checkCreateContact?.accessAll || checkCreateContact?.groupIds?.length != 0) {
        return <ContactListFooter onPress={gotoCreateNewContact} />
      } else {
        return <></>
      }
    }

    return (
      <MainLayout>
        <View style={[styles.container]}>
          <DrawerStatus Icon={<OutboxIcon color={Colors.orange} />} label="送信トレイ" />
          <ContactQueryBuilder
            filterType={ContactFilterType.Outbox}
            filters={filters}
            onFiltersChange={setFilters}
            searchText={searchText}
            onSearchTextChange={setSearchText}
          />
          <FlatList
            data={contacts?.contacts}
            renderItem={ListItem}
            ListFooterComponent={ContactListFooterMargin}
            ListEmptyComponent={!isLoadingContacts ? <ContactListEmpty label="連絡はありません。" /> : undefined}
            refreshControl={<CustomRefreshControl refreshing={isLoadingContacts && isFocused} onRefresh={refreshContacts} />}
            onEndReached={!isLoadingContacts ? getMoreContacts : undefined}
            keyExtractor={(item) => item.id}
          />
          {renderCreateContactButton()}
        </View>
      </MainLayout>
    )
  }
)

OutboxScreen.displayName = "OutboxScreen"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  contentContainer: {
    marginHorizontal: 16,
    marginTop: 10,
  },
})

export const outboxScreenOptions = (showBadge: boolean): DrawerScreenOptions => ({
  title: "送信トレイ",
  drawerIcon: (props) => (
    <DrawerIconContainer>
      <OutboxIcon {...props} color={Colors.orange} />
    </DrawerIconContainer>
  ),
  drawerLabel: () => <DrawerLabel title={"送信トレイ"} showBadge={showBadge} />,
})
