import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { pick } from "lodash"
export const accountEditState = atom({
  key: "atoms/account/accountEditState",
  default: selector({
    key: "atoms/account/accountEditState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return pick(account, [
        "firstName",
        "lastName",
        "kanaFirstName",
        "kanaLastName",
        "birthDate",
        "gender",
        "jobId",
        "schoolId",
        "schoolDepartmentId",
        "graduationYear",
        "prefectureId",
        "cityId",
        "questionAnswers",
      ])
    },
  }),
})
