import { useEffect, useCallback } from "react"
import { BackHandler, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { Screens } from "src/constants/Screens"
import { CustomAlert } from "src/utils/CustomAlert"
import { HeaderBackButtonProps } from "@react-navigation/elements"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { Colors } from "src/constants/Colors"

export const useAccountEditorHeaderWithBackHandler = (isDirty: boolean, isLargeScreen: boolean) => {
  const navigation = useNavigation()
  const dialogTitle = "確認"
  const dialogMessage = "本当に画面を移動しますか？\n変更内容は削除されます。"

  useEffect(() => {
    if (Platform.OS !== "android") return

    // for android back button
    const backAction = () => {
      if (isDirty) {
        CustomAlert.confirm(dialogTitle, dialogMessage).then((isConfirmed) => {
          if (isConfirmed) {
            navigation.canGoBack() ? navigation.goBack() : navigation.reset({ index: 0, routes: [{ name: Screens.Root }] })
          }
        })
        return true
      }
      return false
    }

    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction)
    return () => backHandler.remove()
  }, [isDirty, navigation])

  const headerGoBack = useCallback(
    async (onPress?: () => void, canGoBack?: boolean) => {
      if (isDirty) {
        const isConfirmed = await CustomAlert.confirm(dialogTitle, dialogMessage)
        if (!isConfirmed) {
          return
        }
      }
      canGoBack ? onPress?.() : navigation.reset({ index: 0, routes: [{ name: Screens.Root }] })
    },
    [isDirty, navigation]
  )

  // gestureEnabled is an option that controls whether the back swipe gesture is enabled or disabled on iOS.
  useEffect(() => {
    navigation.setOptions({
      headerLeft: ({ onPress, canGoBack }: HeaderBackButtonProps) => (
        <Button onPress={() => headerGoBack(onPress, canGoBack)}>
          <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3} />
        </Button>
      ),
    })
  }, [navigation, headerGoBack, isLargeScreen])
}
